<template>
  <div id="FileNameAndPreview">
    <div v-if="true" @mouseover="showPreview = true" @mouseleave="showPreview = false" class="Box">
      <div :class="['crop', 'textBlue', { cursorPointer: downLoad }]" @click="DownLoadDocument(detail.fileFullUrl)">
        {{ detail.fileName }}
      </div>
      <div :class="['reviewBox', position, { showPreview: showPreview }]" @click="$event.stopImmediatePropagation()">
        <PreviewFile :showPreview="showPreview" :width="300" :height="300" :uploadDocument="detail" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    PreviewFile: () => import("./PreviewFile"),
  },
  computed: {},
  props: {
    detail: {
      type: Object,
    },
    position: {
      type: String,
      default: "top",
    },
    downLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPreview: false,
      otherUser: null,
    };
  },

  methods: {
    DownLoadDocument(linkFile) {
      if (this.downLoad) {
        window.open(linkFile);
      }
    },
    SetIconFile(detail) {
      let icon = "";
      let color = "";
      if (detail.fileType.includes("image")) {
        icon = "icon-file_img";
        color = "#6B70F2";
      } else if (detail.fileType.includes("pdf")) {
        icon = "icon-file_pdf";
        color = "red";
      } else if (detail.fileName.toLowerCase().includes(".xlsx") || detail.fileName.toLowerCase().includes(".xls")) {
        icon = "icon-file_exel";
        color = "#007534";
      } else if (detail.fileName.toLowerCase().includes(".doc") || detail.fileName.toLowerCase().includes(".docx")) {
        icon = "icon-file_word";
        color = "#19579F";
      } else if (detail.fileName.toLowerCase().includes(".ppt") || detail.fileName.toLowerCase().includes(".pptx")) {
        icon = "icon-file_ppt";
        color = "#E1380F";
      } else {
        icon = "icon-file_other";
        color = "#3DC0CB";
      }
      return { icon: icon, color: color };
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin crop($line, $width: 100%) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if $line == 1 {
    white-space: nowrap;
  } @else {
    display: -webkit-box;
  }
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  max-width: $width;
}
@mixin shadowboxLv2 {
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.2);
  //   box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06);
}
#FileNameAndPreview {
  position: relative;
  display: flex;
  width: fit-content;
  .crop {
    @include crop(100%);
    .detail {
      font-size: 12px;
      color: #d5d5d5;
    }
  }

  .email.crop {
    @include crop(180px);
    .detail {
      font-size: 12px;
      color: #d5d5d5;
    }
  }
  .Box {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    > div {
      margin-right: 14px;
    }
    .index {
      color: #9d9eb1;
    }
    .icon-files {
      i {
        font-size: 14px;
      }
    }
  }
  .reviewBox {
    position: absolute;
    left: 100%;
    background: white;
    z-index: 2;
    width: 0px;
    height: 0px;
    transition: 0.25s;
    overflow: hidden;
    top: 0;
    border-radius: 6px;
    @include shadowboxLv2;
  }
  .reviewBox.top {
    top: unset;
    bottom: 0;
  }
  .showPreview {
    width: 300px;
    height: 300px;
  }
  .textBlue {
    color: #3297ff;
  }
}
</style>
